div.errorPageWrapper {
  width: 100%;
  height: 100%;
  background-color: white;
  text-align: center;
  z-index: 9999;
  position: relative;
}

img.showboatLogo {
  float: left;
  width: 200px;
  height: 200px;
  display: inline-block;
  position: relative;
}


div.supportHolder {
  width: 100%;
  height: 80px;
  position: relative;
  top: -10px;
  left: 2px;
}

div.supportOptionHolder {
  width: 100%;
  height: 50px;
  position: relative;
  top: 10px;
}

div.errorMessageHolder {
  width: 100%;
  height: 350px;
  position: relative;
  top: 30px;
  left: 2px;
  overflow-y: auto;
  overflow-x: hidden;
}

div.errorOptionHolder {
  width: 100%;
  position: absolute;
  bottom: 185px;
  left: 0px;
  height: 200px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

div.errorOption {
  width: 100%;
  height: 50px;
  margin-bottom: -90px;
}

div.errorOptionHangup {
  position: relative;
  top: 12px;
}

div.hangUpURLHolder {
  width: 100%;
  position: relative;
  height: 50px;
  top: -105px;
  left: 2px;
}

div.registrationEmailHolder {
  width: 100%;
  position: relative;
  height: 50px;
  top: -30px;
  margin-bottom: 270px;
}
div.sendEmailPresentersHolder {
  width: 100%;
  position: relative;
  height: auto;
  top: 285px;
  margin-bottom: 326px;
}


div.presenterListHeader {
  width: 100%;
  height: 32px;
  position: absolute;
  background-color: #EFEFEF;
}

div.presenterListHolder {
  height: auto;
  position: relative;
  top: 30px;
  width: 100%;
  overflow-y: auto;
  min-height: 70px;
  max-height: calc(100vh - 540px);
}

div.sendEmailAttendeesHolder {
  width: 100%;
  position: absolute;
  height: 695px;
  bottom: 0px;
}

div.searchHolder {
  position: absolute;
  top: 175px;
  width: 100%;
  height: 110px;
  left: -28px;
}

div.attendeeListHeader {
  width: 100%;
  height: 32px;
  position: absolute;
  top: 110px;
  background-color: #EFEFEF;
}

div.attendeeListHolder {
  height: 555px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 70px;
  overflow-y: auto;
}
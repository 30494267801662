div.fileUploadHolder {
   display: inline-block;
  width: 500px;
  min-height: 220px;
  height: auto;
  position: relative;
  left: 30px;
  top: 86px;
}

div.dropzone {
  width: 600px;
  height: 116px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  transition: border .24s ease-in-out;
}

div.fileResultHolder {
  position: relative;
  width: 602px;
  top: 15px;
  left: 0px;
}

div.fileSuccessHolder {
  width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  border: 1px solid #EBEBEB;
  height: 47px;
}

div.fileFailureHolder {
  width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  height: 67px;
  border: 1px solid #EBEBEB;
}

.aside {
  position: relative;
  top: 15px;
}

li.rejectedItem {
  width: 250px;
  word-wrap: break-word;
}

li.fileName {
  width: 250px;
  word-wrap: break-word;
}

.failIcon {
  color: red;
  width: 24px;
  height: 24px;
  top: 22px;
  right: 20px;
  position: absolute;
}

.checkIcon {
  color: green;
  width: 24px;
  height: 24px;
  top: 12px;
  right: 20px;
  position: absolute;
}
div.forgotPasswordHolder {
  position: relative;
  margin: auto;
  display: inline-block;
  left: 44px; 
  top: 80px;
  height: 450px;
  width: 328px;
  text-align: center;
}

div.forgotPasswordHolderVerification {
  margin-bottom: 160px;
}

div.errorHolderPassword {
  position: relative;
  top: -5px;
  margin-bottom: 10px;
}
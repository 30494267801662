div.iconHolder {
  display: flex;
  position: absolute;
  height: 20px;
  width: 85px;
  right: 11px;
  top: -9px;
  flex-direction: row;
  justify-content: space-evenly;
}

div.attendeeHolder {
  min-height: 190px;
  height: auto;
  width: 100%;
  position: relative;
  top: 100px;
  left: 0px;
  overflow-y: auto;
  margin-bottom: 329px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div.attendeeHolder::-webkit-scrollbar {
  width: 0.4em;
}

div.attendeeHolder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div.attendeeHolder::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.425);
  border-radius: 15px;
}

div.showboatLogoHolder {
  width: 100%;
  height: 40px;
  position: relative;
  top: 60px;
  text-align: center;
}

div.loginWrapper {
  display: flex;
  vertical-align: middle;
}


div.currentAttendeeHolder {
  width: 100%;
  height: 47px;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid #EBEBEB;
}

div.nameHolder {
  position: absolute;
  left: 29px;
  top: 15px;
  display: inline-block;
  width: 320px;
  height: 20px;
}

div.badge {
  position: absolute;
  top: 18px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #04B7FF;
}

div.brandBadge {
  left: 235px;
}

div.presenterBadge {
  left: 313px;
}

div.inRoomBadge {
  left: 409px;
}


.avatarFaceBorder {
  position: absolute;
  top: 26px;
  left: 30px;
  width: 200px;
  height: 200px;
  z-index: 11;
}

.avatarFace {
  position: absolute;
  width: 188px;
  height: 190px;
  top: 29px;
  left: 38px;
}

div.avatarHolder {
  position: absolute;
  top: 85px;
  width: 45%;
  height: 300px;
}

div.selectionHolder {
  width: 45%;
  height: 300px;
  position: absolute;
  right: 135px;
  top: 23px;
  text-align: center;
}

form.colorForm {
  width: 510px;
  height: 230px;
  display: flex;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  left: -20px;
  top: 60px;
}

input.colorInput {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
}

input.colorInput:hover {
  cursor: pointer;
}


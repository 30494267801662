div.permissionsHolder {
  width: 938px;
  left: 31px;
  display: flex;
  position: relative;
  flex-direction: row;
  top: 127px;
  justify-content: space-evenly;
  height: 68%;
}

div.generalPermissions {
  width: 50%;
  height: 100%;
  position: relative;
  top: 43px;
}

div.displayPermissions {
  width: 50%;
  height: 100%;
  position: relative;
  top: 43px;
}

div.permissionsItem {
  display: flex;
  width: 88%;
  height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 24px;
}

@media only screen and (max-width: 959.95px) {
  div.permissionsHolder {
    width: 682px;
  }
}
div.calendarItemHolder {
  position: relative;
  border-bottom: 1px solid #EBEBEB;
  display: inline-block;
  width: 100%;
  height: 47px;
  margin-bottom: 0px;
}

div.calendarItemHolderSafari { 
  margin-bottom: 0px;
}

div.spaceAdminGrid {
  display: grid;
  grid-template-columns: 50% 50%;
}

div.spaceFormHolder {
  position: relative;
  width: 90%;
  height: 710px;
  top: 20px;
  left: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

div.spaceAdminDateHolder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

div.spaceFormItem {
  width: 100%;
  height: 90px;
  display: inline-block;
  position: relative;
}

div.spaceFormItemWorld {
  top: 15px;
}

div.dateInputHolder {
  width: 70%;
  height: 100px;
  position: relative;
  left: 50px;
}

.timezonePicker {
  top: 20px;
  text-align: left;
}

.timezonePickerError {
  border-color: red !important;
}


div.adminMenuWrapper {
  width: 100%;
  height: 100%;
}

div.adminMenuHolder {
  width: 100px;
  height: 400px;
  position: absolute;
  display: flex;
  margin-left: calc(50% - 50px);
  flex-direction: column;
  justify-content: space-evenly;
}
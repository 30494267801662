div.attendeeItemHolder {
  width: 100%;
  height: 47px;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: -4px;
}

div.attendeeItemHolderSelected {
  background-color: lightgray;
}

div.attendeeItemHolder:hover {
  cursor: pointer;
}
div.slideHolder {
  width: 895px;
  height: auto;
  min-height: 70px;
  max-height: calc(100vh - 620px);
  overflow-y: auto;
  display: inline-block;
  position: relative;
  bottom: 0px;
  left: 30px;
  top: 250px;
  margin-bottom: 380px;
}

div.slideItem {
  display: inline-block;
  margin-right: 15px;
  left: 5px;
  margin-bottom: 3px;
  position: relative;
  height: 110px;
  width: 160px;
  border: 1px solid transparent;
  z-index: 12;
}

div.slideItem:hover {
  cursor: pointer;
}

div.slideItemSelected {
  border: 1px solid black;
}

img.slideImage {
  width: 160px;
  height: 90px;
  position: absolute;
  bottom: 0px;
}

@media only screen and (max-width: 959.95px) {
  div.slideHolder {
    width: 600px;
  }

  div.slideItem {
    margin-right: 30px;
  }
}
div.popupHeader {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 37px;
  background-color: #00B6FF;
}

div.attendeeGridHolder {
  width: 741px;
  height: 241px;
  position: absolute;
  left: 30px;
  bottom: 30px;
  display: grid;
  grid-template-columns: 51% 50%;
}

div.informationItemHolder {
  width: 362px;
  height: 46px;
  position: relative;
  border-bottom: 1px solid #EBEBEB;
}
div.loadingSpinnerHolder {
  width: 100%;
  height: 100%;
  background-color: white;
}

div.loadingContentHolder {
  position: relative;
  margin: auto;
  display: inline-block;
  left: 41px; 
  bottom: 0; 
  right: 0;
  height: 350px;
  width: 328px;
  text-align: center;
}

div.showboatLogoHolder {
  width: 100%;
  height: 40px;
  position: relative;
  top: 60px;
  text-align: center;
}
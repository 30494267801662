div.cacheFormHolder {
  width: 90%;
  height: 200px;
  display: inline-block;
  position: relative;
  top: 40px;
}

div.cacheResultsHolder {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  grid-template-rows: auto;
  position: relative;
  height: auto;
  top: 40px;
  left: 35px;
  text-align: left;
  max-height: 650px;
  overflow:auto;
}

div.cacheResultsHolder div {
  margin-bottom: 10px;
}

div.cacheResultsHolder div.collapsible {
  position: relative;
  right: 20px;
}

div.itemHolder {
  position: relative;
  text-align: left;
}
div.colorHolder {
  display: inline-block;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 31px;
  left: 10px;
  border-radius: 5px;
}

div.previewContentHolder {
  position: relative;
  top: 60px;
  left: 15px;

}


div.intakeUrlHolder {
  width: 100%;
  height: 30px;
  position: relative;
  top: 26px;
}

div.mobileHybridUrlHolder {
  top: 44px;
}

div.intakeURLHolderPhotoAlbum {
  top: 64px;
}

div.intakeURLHolderPhotoAlbumNoMobileHybrid {
  height: 10px;
  top: 49px;
}

div.calendarEntryToggleHolder {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 100px;
}

div.calendarEntryHolder {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 145px;
}

input.hiddenInput {
  
}

div.intakeButtonHolder {
  position: absolute;
  right: 10px;
  top: 6px;
}

div.mobileHybridButtonHolder {
  position: absolute;
  right: -14px;
  top: 6px;
}

div.photoAlbumButtonHolder {
  top: 15px;
  right: 22px;
}

div.runHolder {
  height: 188px;
  width: 100%;
  position: relative;
  top: 98px;
}

div.runHolderCalendarSelected {
  top: 202px;
}

div.limitHolder {
  height: auto;
  bottom: -90px;
  position: relative;
  width: 100%;
}

div.limitHolderCalendarSelected {
  bottom: -102px;
}

div.editIconHolder {
  width: 80px;
  height: 35px;
  position: relative;
  display: inline-block;
  top: -5px;
  margin-left: 215px;
}

img.copyImage {
  width: 20px;
  height: 20px;
}

div.bookingOverviewWrapper {
  position: relative;
  height: auto;
  margin-bottom: 290px
}
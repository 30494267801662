div.itemHolder {
  position: relative;
  width: 100%;
  height: 46px;
  top: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.privateCloudHolder {
  position: relative;
  width: 100%;
  height: 20px;
  top: 37px;
}

img.copyImage {
  width: 20px;
  height: 20px;
}
form.attendeeForm {
  display: flex;
  flex-direction: column;
  width: 985px;
  height: 472px;
}

div.column1 {
  width: 50%;
  height: 472px;
  text-align: center;
  position: absolute;
  display: inline-block;
  top: 0px;
  left: 0px;
}

div.column2 {
  width: 50%;
  height: 472px;
  position: absolute;
  display: inline-block;
  text-align: center;
  top: 0px;
  right: -14px;
}
video.previewVideo {
  display: inline-block;
  position: relative;
  height: auto;
  width: 100%;
}

video.previewVideo:focus {
  outline: none;
  border: none;
}

img.previewImage {
  top: 0px;
  position: relative;
  width: 100%;
  height: auto;
}

img.previewImageHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 108px;
  margin: auto;
  height: auto;
  max-height: 100%;
}

img.previewImageLogo {
  width: 145px;
  height: 145px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

div.imageHolder {
  position: absolute;
  width: 720px;
  height: 425px;
  top: 95px;
  left: 30px;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.videoHolder {
  position: absolute;
  width: 720px;
  height: 425px;
  top: 95px;
  left: 30px;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.audioPreview {
  position: relative;
  top: 38%;
}

@media only screen and (max-width: 959.95px) {
  div.imageHolder {
    width: 640px;
    height: 360px;
    top: 70px;
  }
}
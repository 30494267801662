div.startHolder {
  width: 50%;
  position: absolute;
  height: 25px;
  top: 75px;
}

div.endHolder {
  width: 50%;
  position: absolute;
  height: 25px;
  top: 125px;
}

div.doorsOpenHolder {
  width: 50%;
  position: absolute;
  height: 25px;
  top: 75px;
  right: 13px;
}

div.doorsClosedHolder {
  width: 50%;
  position: absolute;
  height: 25px;
  top: 125px;
  right: 13px;
}

div.startTimeHolder {
  position: absolute;
  left: 120px;
  top: -10px;
  width: 200px;
}

div.endTimeHolder {
  position: absolute;
  left: 120px;
  top: -10px;
  width: 200px;
}
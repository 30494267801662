div.emailHolder {
  height: auto;
  min-height: 144px;
  width: 100%;
  position: relative;
  top: 100px;
  left: 0px;
  overflow-y: auto;
  margin-bottom: 329px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
div.popupHeader {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 37px;
  z-index: 10;
  background-color: #00B6FF;
}

div.iconHolder {
  position: absolute;
  left: 28px;
  top: 27px;
}
div.buttonHolder {
  width: 315px;
  height: 30px;
  position: absolute;
  top: 217px;
  right: 5px;
}

@media only screen and (max-width: 959.95px) {

  div.buttonHolder {
    top: 208px;
    right: 50px
  }

} 
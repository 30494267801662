div.producerFormHolder {
  width: 90%;
  height: 600px;
  display: inline-block;
  position: relative;
  top: 50px;
}

div.producerFormItem {
  width: 100%;
  height: 90px;
  display: inline-block;
  position: relative;
}

div.producerFormItemTransferList {
  width: 650px;
  height: 90px;
  display: inline-block;
  position: relative;
  float: left;
  top: 40px;
}


div.listButtonsHolder {
  width: 50px;
  height: 250px;
  display: flex;
  position: relative;
  top: 50px;
  left: 176px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

div.leftListHolder {
  position: absolute;
  left: 0px;
  top: 30px;
}

div.rightListHolder {
  position: absolute;
  right: 0px;
  top: 30px;
}

div.producerNameHolder {
  position: absolute;
  width: 40%;
  height: 250px;
  top: 70px;
  right: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

div.producerNameItem {
  position: relative;
}



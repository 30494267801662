div.spacePickerHolder {
  width: 250px;
  height: 46px;
  position: relative;
  top: 17px;
  left: 0px;
  z-index: 9998;
}

div.spacePickerHolder:hover {
  cursor: pointer;
}

div.projectsHolder {
  width: 250px;
  height: auto;
  min-height: 35px;
  position: absolute;
  top: 50px;
  left: 0px;
  background-color: black;
  z-index: 9998;
  padding-top: 30px;
}

div.projectItem {
  position: relative;
  width: 100%;
  height: 35px;
}

div.projectItem:hover {
  background-color: #4E4E4E;
}

div.projectItem:hover {
  cursor: pointer;
}

div.spaceHolder {
  width: 250px;
  height: auto;
  background-color: black;
  position: relative;
  left: 250px;
}
div.calendarItemHolder {
  width: 100%;
  height: 48px;
  position: relative;
  display: inline-block;
}

div.calendarItemHolderSelected {
  background-color: #E5E5E5;
}

div.calendarItemHolderSelected:hover, div.calendarItemHolder:hover {
  cursor: pointer;
}


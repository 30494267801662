div.calendarEditHolder {
  display: inline-block;
  position: relative;
  top: 0px;
  width: 100%;
  height: 220px;
}

div.inputIconHolder {
  width: 80px;
  height: 35px;
  position: relative;
  display: inline-block;
  top: 77px;
  margin-left: 50px;
}

div.calendarURLHolder {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 150px
}

img.copyIcon {
  width: 20px;
  height: 20px;
}
div.mainWrapper {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  margin: auto;
  width: 974px;
  height: auto;
}

div.navHeaderHolder {
  display: inline-block;
  width: 990px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

img.showboatLogo {
  position: absolute;
  left: 0px;
  top: -15px;
  display: inline-block;
  width: 80px;
  height: 80px;
}

div.navHolder {
 
}

div.buttonHolder {
  position: absolute;
  display: inline-block;
  width: 228px;
  top: 20px;
  right: 50px;
  z-index: 9998;
}

div.spaceInfoHolder {
  height: 100%;
  width: 250px;
  display: inline-block;
  position: absolute;
  text-align: left;
  top: 94px;
  left: 2px;
}

div.spaceInfoItem {
  position: relative;
}

img.welcomeLogo {
  position: absolute;
  top: 105px;
  left: 137px;
}

img.showboatLogoHeader {
  position: absolute;
  left: 31px;
  top: 20px;

}

@media only screen and (max-width: 1280.95px) {
  div.spaceInfoHolder {
    left: 5px;
  }

  img.showboatLogo {
    left: -15px;
  }
}

@media only screen and (max-width: 1160px) {
  img.showboatLogoHeader{
    display: none;
  }
}

@media only screen and (max-width: 959.95px) {

  div.navHeaderHolder {
    width: 100%;
  }
  
  div.spaceInfoHolder {
    left: 23px;
    top: 160px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    height: 150px;
  }

  div.mainWrapper {
    width: 100%;
    min-width: 730px;
  }

  img.welcomeLogo {
    left: 70px;
  }
}
div.timezonePicker {
  width: 472px;
  position: absolute;
  top: 170px;
  left: 15px;
}

div.contentWrapper {
  position: relative;
  top: 25px;
}
div.presentationsHolder {
  width: 100%;
  position: relative;
  top: 57px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  overflow-y: visible;
  margin-bottom: 56px;
  border-top: 1px solid #E0E0E0;
}
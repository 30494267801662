div.brandingNav {
  height: 80px;
  width: 230px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: absolute;
  top: 91px;
  left: 25px;
}

div.brandingViewHolder {
  position: relative;
  width: 100%;
  height: auto;
  top: 125px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 124px;
  border-top: 1px solid #E0E0E0;
}

div.optionWrapper {
  position: relative;
  top: 25px;
}

span.optionHolderSelected {
  background-color: #ececec;
  border-radius: 15px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  position: relative;
  
}

span.optionHolderSelectedInRoom {
  width: 80px;
}

span.optionHolderSelectedWebTemplate {
  width: 60px;
}

span.optionHolderSelectedEmail {
  width: 70px;
}
div.bodyColor {
  display: inline-block;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 16px;
  left: 16px;
  border-radius: 5px;
}

div.bodyColor:hover, div.faceColor:hover {
  cursor: pointer;
}

.colorPicker {
  position: absolute;
  z-index: 9999;
  left: 80px;
  width: 235px !important;
  margin-bottom: 30px;
}

.colorPickerEnd {
  position: absolute;
  z-index: 9999;
  left: -230px;
  width: 235px !important;
  margin-bottom: 10px;
}

div.faceColor {
  display: inline-block;
  position: absolute;
  bottom: 19px;
  left: 16px;
  width: 54px;
  height: 54px;
  border-radius: 5px;
}

div.editIconHolder {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 5px;
  top: 5px;
  background-color: white;
  border-radius: 2px;
}

div.disabled {
  border: 1px dashed #9F9F9F;
  background-color: #E2E2E2;
}

div.error {
  border: 1px dashed #FF8181;
  background-color: #FFE8E8;
}

@media only screen and (max-width: 959.95px) {
  .colorPicker {
    left: 75px;
  };

  
}
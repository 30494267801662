div.searchBarHolder {
  position: relative;
  width: 100%;
  height: 84px;
  display: inline-block;
  bottom: 41px;
}

div.countWrapper {
  width: 54px;
  height: 37px;
  border: 1px solid black;
  border-radius: 5px;
  position: absolute;
  right: 124px;
  top: 25px;
  text-align: center;
}

div.currentAttendeesHolder {
  height: auto;
  min-height: 150px;
  width: 100%;
  position: relative;
  top: 10px;
  left: 0px;
  overflow-y: auto;
  margin-bottom: 239px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div.iconHolder {
  position: absolute;
  right: 2px;
  top: 29px;
  width: 112px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

div.currentAttendeesHolder::-webkit-scrollbar {
  width: 0.4em;
}

div.currentAttendeesHolder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div.currentAttendeesHolder::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.425);
  border-radius: 15px;
}
div.bookingsOverviewHolder {
  width: 90%;
  height: 400px;
  position: relative;
  top: 40px;
  display: inline-block;
}

div.calendarHolder {
  width: 36%;
  height: 350px;
  position: relative;
  top: 70px;
  float: left;
}

div.bookingsHolder {
  width: 68%;
  height: 450px;
  position: absolute;
  display: inline-block;
  right: -15px;
  top: 60px;
}

div.bookingsItemHolder {
  width: 100%;
  height: 450px;
  position: absolute;
  overflow: auto;
}

div.bookingsHeaderHolder {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: #E5E5E5;
}

div.bookingsItemHolder {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 400px;

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div.app {
  width: 100%;
  height: 100%;
  min-width: 700px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

img {
  z-index: 10;
}


div.collapsible {
  position: relative;
  right: 40px;
}

.j2hlabel {
  background-image: none !important;
}


.MuiInputBase-input {
  opacity: 1 !important;
  color: #636363 !important;
  font-size: 16px;
 /*  border: none !important; */
 border-radius: 4px;
}

.MuiOutlinedInput-input {
  background-color: #F7F7F7 !important;
  color: #5F5F5F !important;
}

.MuiOutlinedInput-inputAdornedEnd {
  background-color: #F7F7F7 !important;
  color: #5F5F5F !important;
}

.MuiOutlinedInput-adornedEnd {
  background-color: #F7F7F7;
}

.MuiTextField-root {
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid #767676 !important;
}

.MuiOutlinedInput-inputMultiline {
  background-color: white !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.MuiDialog-paperWidthSm {
  max-width: none !important;
}

.MuiButton-label {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  position: relative;
  top: 1px;
}

.downloadLink {
  color: black;
}

.downloadLinkQA {
  position: absolute;
  right: 26px;
  bottom: 0px;
}

.MuiOutlinedInput-inputMultiline {
  margin: auto !important;
  height: 100% !important;
}

.MuiPickersDatePickerRoot-toolbar {
  background-color: #00B6FF !important;
}

.MuiPickersDay-current {
  color: green !important;
}

.MuiPickersDay-daySelected {
  background-color: #00B6FF !important;
}

.MuiPickersDay-daySelected .MuiIconButton-label {
  color: white !important;
}

.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding {
  background-color: #00B6FF !important;
}

.MuiPickersClockNumber-clockNumberSelected {
  background-color: #00B6FF !important;
}

div::-webkit-scrollbar {
  width: 0.4em;
}

div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  position: relative;
  right: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div::-webkit-scrollbar-thumb {
  background-color: #5a5f69;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  border-radius: 15px;
}
div.emailOptionsHolder {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 470px;
  left: 30px;
  top: 159px;
}

@media only screen and (max-width: 959.95px) {
  div.emailOptionsHolder {
    height: 275px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    left: 0px;
  }
}

div.avatarColorsHolder {
  width: 890px;
  height: 339px;
  display: inline-block;
  position: relative;
  left: 30px;
  top: 80px;
}

div.column1 {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

div.column2 {
  height: 50%;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media only screen and (max-width: 959.95px) {
  div.avatarColorsHolder {
    width: 730px;
  }
}
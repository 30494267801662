div.attendeeDetailsGrid {
  position: relative;
  top: 85px;
  left: 42px;
  min-height: 173px;
  width: 675px;
  display: grid;
  grid-template-columns: 60% 40%;
}

div.addressHolder {
  min-height: 173px;
  width: 100%;
  position: relative;
  display: inline-block;
} 

div.popupHeader {
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  height: 37px;
  background-color: #00B6FF;
}

div.timeDetailsHolder {
  display: inline-block;
  width: 256px;
  min-height: 264px;
  position: absolute;
  right: 0px;
  top: 112px;
}

div.tableHolder {
  width: 797px;
  height: 139px;
  overflow-y: auto;
  display: inline-block;
  position: absolute;
  bottom: 30px;
  left: 29px;
  border: 1px solid #EBEBEB;
}

div.tableHeaderHolder {
  position: absolute;
  left: 29px;
  top: 90px;
  width: 799px;
  height: 30px;
  background-color: #CBCBCB;
}

@media only screen and (max-width: 959.95px) {

}
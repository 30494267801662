div.usageRepairHolder {
  position: relative;
  width: 90%;
  display: inline-block;
  height: 250px;
  top: 50px;
}

div.attendeeSelectHolder {
  width: 500px;
  position: absolute;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 240px;
}
div.itemHolder {
  height: 47px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #E0E0E0;
}

img.uploadIcon {
  width: 18px;
  height: 18px;
}
div.systemCheckAdminHolder {
  width: 90%;
  height: 560px;
  display: inline-block;
  position: relative;
  top: 40px;
}

div.calendarHolder {
  width: 34%;
  height: 350px;
  position: relative;
  top: 70px;
  float: left;
}

div.systemCheckHolder {
  width: 63%;
  height: 450px;
  position: absolute;
  display: inline-block;
  right: -15px;
  top: 60px;
}

div.systemCheckItemHolder {
  width: 100%;
  height: 450px;
  position: absolute;
  overflow: auto;
  text-align: center;
  top: 50px;
}
div.presenterHolder {
  min-height: 190px;
  width: 100%;
  position: relative;
  top: 100px;
  left: 0px;
  overflow-y: auto;
  margin-bottom: 329px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div.presenterHolder::-webkit-scrollbar {
  width: 0.4em;
}

div.presenterHolder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div.presenterHolder::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.425);
  border-radius: 15px;
}
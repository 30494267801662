div.startDateWrapper {
  position: absolute;
  height: 47px;
  width: 130px;
  text-align: left;
  left: 228px;
  display: inline-block;
  top: 5px;
}

div.endDateWrapper {
  position: absolute;
  height: 47px;
  width: 130px;
  text-align: left;
  left: 383px;
  display: inline-block;
  top: 5px;
}

div.registrationCountWrapper {
  position: absolute;
  width: 30px;
  right: 143px;
  height: 30px;
  top: 6px;
  text-align: left;
}
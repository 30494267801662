div.bookingsHeaderHolder {
  width: 100%;
  position: absolute;
  top: 140px;
  left: 0px;
  height: 25px;
  border-bottom: 1px solid #EBEBEB;
}

div.bookingsHolder {
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 93px;
  left: 0;
  overflow-y: visible;
  margin-bottom: 92px;
}

div.bookingsHolder::-webkit-scrollbar {
  width: 0.4em;
}

div.bookingsHolder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div.bookingsHolder::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.425);
  border-radius: 15px;
}
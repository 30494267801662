div.presentationItemHolder {
  height: 46px;
  width: 100%;
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #E0E0E0;
}

div.presentationItemHolderSafari {
  margin-bottom: 0px;
}
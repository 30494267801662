div.buttonTopHolder {
  width: 600px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position:absolute;
  right: 5px;
  top: 64px;
}

div.buttonTopHolderPresenter {
  top: 51px;
}

div.allowEarlyAccessToggleHolder {
  position: absolute;
  left: 160px;
  bottom: 74px;
}

div.brandToggleHolder {
  position: absolute;
  left: 230px;
  bottom: 34px;
}

div.allowEarlyAccessToggleHolder {
  position: absolute;
  left: -14px;
  bottom: 34px;
}

div.allowEarlyAccessToggleHolderPresenter {
  bottom: -24px;
}

div.attendeeFormHolder {
  width: 93%;
  height: 380px;
  position: absolute;
  top: 101px;
  left: 29px;
}

div.attendeeFormHolderPresenter {
  top: 160px;
}

div.popupHeader {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 37px;
  background-color: #00B6FF;
}

div.tabBackdrop {
  display: inline-block;
  background-color: #DCDCDC;
  height: 48px;
  position: relative;
  top: 100px;
  left: 30px;
  width: 940px;
}

div.linkHolder {
  width: 943px;
  position: relative;
  height: 102px;
  top: 150px;
  left: 30px;
  display: inline-block;
}

div.linkHolderBridge {
  
}

div.presenterLinkButtonHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 180px;
  position: absolute;
  top: 40px;
  right: -14px;
}

div.presenterLinkButtonHolderBridge {
  width: 89px;
  right: -12px;

}

@media only screen and (max-width: 959.95px) {

  div.brandToggleHolder {
    bottom: 40px;
  }

  div.allowEarlyAccessToggleHolder {
    bottom: 40px;
    left: 0px;
  }
  
  div.attendeeFormHolder {
    top: 140px;
    left: 19px;
  }
  
  div.buttonTopHolder {
    position: absolute;
    left: 10px;
    top: 105px;
  }

  div.tabBackdrop {
    width: 641px;
  }
  
  div.linkHolder {
    width: 641px;
  }

}

div.brandToggleHolder {
  position: absolute;
  left: 220px;
  bottom: 34px;
}

div.allowEarlyAccessToggleHolder {
  position: absolute;
  left: -15px;
  bottom: 34px;
}

div.attendeeFormHolder {
  width: 94%;
  height: 470px;
  position: absolute;
  top: 100px;
  left: 25px;
}

@media only screen and (max-width: 959.95px) {
  div.attendeeFormHolder {
    left: 16px;
  }
}
div.loginCodeHolder {
  width: 100%;
  padding-left: 35px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  position: relative;
  top: 50px;
}

div.bookingLinkHolder {
  position: absolute;
  width: 100%;
  bottom: -50px;
  text-align: left;
}
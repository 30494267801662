div.registrationFieldsHolder {
  height: 470px;
  width: 100%;
  position: relative;
  top: 25px;
  left: 0px;
  overflow-y: hidden;
  margin-bottom: 310px;
}

div.registrationFieldsHolder::-webkit-scrollbar {
  width: 0.4em;
}

div.registrationFieldsHolder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div.registrationFieldsHolder::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.425);
  border-radius: 15px;
}

div.basicHolder {
  height: 220px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  left: 30px;
  top: 80px;
}

div.addressHolder {
  height: 147px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  bottom: -10px;
  left: 30px;
}

div.requiredHolderBasic {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80px;
  height: 217px;
  top: 83px;
  left: 295px;
}

div.addressRequiredCheckboxHolder {
  position: absolute;
  left: 266px;
  bottom: 95px;
}

div.registrationCapacityHolder {
  position: absolute;
  right: 30px;
  top: 65px;
  width: 297px;
  height: 140px;
}

div.signUpUrlHolder {
  width: 100%;
  display: inline-block;
  position: absolute;
  height: 30px;
  top: -15px;
}

div.signUpButtonHolder {
  position: absolute;
  right: 10px;
  top: 0px;
}
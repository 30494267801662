div.highlightColorHolder {
  height: 104px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #E0E0E0;
}

div.colorHolder {
  display: inline-block;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 31px;
  left: 10px;
  border-radius: 5px;
}

div.editIconHolder {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 5px;
  top: 5px;
  background-color: white;
  border-radius: 2px;
}

div.editIconHolder:hover {
  cursor: pointer;
}

.colorPicker {
  position: absolute;
  z-index: 9999;
  left: 60px;
  top: -75px;
  width: 250px !important;
}

div.editHolder {
  position: relative;
  top: 60px;
  left: 20px;

}
div.invalidSpacesHolder {
  width: 50%;
  position: relative;
  height: auto;
  min-height: 100px;
  float: left;
  top: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 140px;
  padding-left: 35px;
  margin-bottom: 150px;
}

div.invalidSpacesHolderLeft {
  width: 45%;
  float: left;
}

div.invalidSpacesHolderRight {
  float: right;
  padding-left: 0px;
}

div.invalidItem {
  width: 100%;
  height: auto;
  min-height: 100px;
  text-align: left;
  position: relative;
}

div.invalidItemRight {
  min-height: 160px;
}

div.linkHolder {
  width: 100%;
  position: relative;
}
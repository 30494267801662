div.calendarsHolder {
  height: auto;
  width: 100%;
  overflow-y: visible;
  border-top: 1px solid #EBEBEB;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 92px;
  margin-bottom: 91px;
}
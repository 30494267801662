div.itemHolderLeft {
  height: 75%;
  width: 45%;
  display: flex;
  display: -ms-flexbox;
  position: absolute;
  left: 50px;
  top: 58px;
  flex-direction: column;
  justify-content: space-evenly;
}

div.itemHolderRight {
  height: 70%;
  width: 45%;
  display: flex;
  display: -ms-flexbox;
  position: absolute;
  right: 30px;
  top: 55px;
  flex-direction: column;
  justify-content: space-evenly;
}
div.bookingEditWrapper {
  width: 100%;
  position: relative;
  height: 610px;
  height: auto;
  top: 70px;
  margin-bottom: 183px;
}

div.bookingEditHeader {
  width: 100%;
  height: 60px;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid #E0E0E0;
}

div.bookingEditNav {
  height: 80px;
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: absolute;
  top: 57px;
  box-sizing: border-box;
}

div.bookingEditMain {
  position: relative;
  width: 100%;
  height: auto;
  top: 160px;
  right: 0px;
}

div.inputIconHolder {
  width: 175px;
  height: 35px;
  position: relative;
  display: inline-block;
  top: 15px;
  margin-left: 50px;
}

div.liveDisplay {
  border: 1px solid gray;
  display: inline-block;
  width: 60px;
  height: 29px;
  position: absolute;
  bottom: 12px;
  right: 10px;
  text-align: center;
  border-radius: 10px;
}

span.optionHolder {
  position: relative;
}

span.optionHolderSelected {
  left: 5px;
  top: 2px;
  background-color: #ececec;
  border-radius: 15px;
  height: 32px;
  width: 70px;
  position: absolute;
}

div.optionWrapper {
  position: relative;
  top: 25px;
}

span.optionHolderSelectedOverview {
  width: 84px;
  left: -5px;
}


span.optionHolderSelectedAttendees {
  width: 97px;
  left: -11px;
}

span.optionHolderSelectedGuestList {
  width: 93px;
  left: -10px;
}

span.optionHolderSelectedRegistration {
  width: 105px;
  left: -12px;
}

span.optionHolderSelectedPresenters {
  width: 97px;
  left: -15px;
}

span.optionHolderSelectedEmail {
  width: 67px;
  left: -18px;
}

span.optionHolderSelectedError {
  width: 95px;
  left: -17px;
}

img.duplicateIcon {
  position: relative;
  left: 12px;
  width: 20px;
  height: 20px;
}

img.duplicateIcon:hover {
  cursor: pointer;
}

div.bookingIDHolder {
  position: absolute;
}
div.personHolder {
  width: 100%;
  height: 47px;
  position: relative;
  display: inline-block;
  overflow: hidden;
}

div.brandDisplay {
  position: absolute;
  top: 19.5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #04B7FF;
  left: 321px;
}

div.sentPreviousDisplay {
  position: absolute;
  top: 19.5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #04B7FF;
  left: 409px;
}
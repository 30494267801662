div.attendeeHolder {
  width: 100%;
  height: 47px;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid #EBEBEB;
}

div.nameHolder {
  position: absolute;
  left: 29px;
  top: 6px;
  display: inline-block;
  width: 320px;
  height: 20px;
}

div.nameHolderNoCompany {
  top: 18px;
}

div.brandDisplay {
  position: absolute;
  top: 19.5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #04B7FF;
  left: 592px;
}

div.iconHolder {
  display: flex;
  position: absolute;
  height: 20px;
  width: 71px;
  right: 18px;
  top: 9px;
  flex-direction: row;
  justify-content: space-evenly;
}
div.emailItemHolder {
  width: 100%;
  height: 47px;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid #EBEBEB;
}

div.htmlDisplay {
  position: absolute;
  top: 19px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #04B7FF;
  left: 342px;
}

div.systemDisplay {
  position: absolute;
  top: 19px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #04B7FF;
  left: 257px;
}

div.iconHolder {
  display: flex;
  position: absolute;
  height: 20px;
  width: 75px;
  right: 19px;
  top: 9px;
  flex-direction: row;
  justify-content: space-evenly;
}